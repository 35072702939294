import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  chatHistory: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    marginBottom: '92px',
  },
  chatBubble: {
    marginBottom: theme.spacing(2),
    borderRadius: '16px',
    padding: theme.spacing(1),
    display: 'inline-block',
    maxWidth: '70%',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  userBubbleContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  userBubble: {
    backgroundColor: '#007AFF',
    color: '#FFF',
  },
  assistantBubbleContainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  assistantBubble: {
    backgroundColor: '#E5E5EA',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#F2F2F7',
  },
  inputBox: {
    flex: 1,
    marginRight: theme.spacing(2),
    backgroundColor: '#FFF',
    borderRadius: '24px',
    paddingLeft: theme.spacing(2),
  },
}));

const ChatBox = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  // Example chatHistory structure:
  // [
  //   { role: 'user', content: 'Hello' },
  //   { role: 'server', content: 'Hi there' },
  //   ...
  // ]
  const [chatHistory, setChatHistory] = useState([]);

  const handleSendMessage = () => {
    sendMessage(message).then(r => {});
    setMessage('');
  };

  const sendMessage = async () => {
    chatHistory.push({role: 'user', content: message});
    const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/chat/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chatHistory }),
    });

    const data = await response.json();
    // Update chat history with the response
    setChatHistory(data.chatHistory);
    setMessage('');
  };

  const clearChat = async () => {
    setChatHistory([]);
  };


  return (
    <Box className={classes.chatContainer}>
      <Box className={classes.chatHistory}>
        {chatHistory.map((message, index) => (
          <Box
            key={index}
            display="flex"
            className={`${message.role === 'user' ? classes.userBubbleContainer : classes.assistantBubbleContainer}`}
          >
            <Box
              className={`${classes.chatBubble} ${message.role === 'user' ? classes.userBubble : classes.assistantBubble}`}
            >
              {message.content}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.inputContainer}>
        <TextField
          className={classes.inputBox}
          label="Type your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant="filled"
          fullWidth
          size="small"
          margin="dense"
          multiline
          maxRows={4}
        />
        <IconButton color="primary" onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
        <IconButton color="secondary" onClick={clearChat}>
          <ClearIcon />
        </IconButton>
      </Box>
    </Box>
  );
};


export default ChatBox;
